import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Eco } from '@material-ui/icons';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { CtaButton } from '@/coreComponents/buttons/CtaButton/CtaButton';
import LinkCaret, { CaretDirection } from '@/coreComponents/links/LinkCaret';
import {
  getMenuItems,
  onMenuInitialLoadAnalytics,
  onNationalClickGetStarted
} from '@/dataAnalytics/dataAnalyticsHelper';
import { openLocalizationRail } from '@/localization/actions';
import telemetry from '@/telemetry';
import useStyle from '../styles';
import MenuTileList from '../MenuTileList';
import { formatTestId } from '@/common/string-formatter';
import CenteredContainer from '@/common/CenteredContainer';
import CategoryTitle from '@/common/components/CategoryTitle';
import { DealStep } from '@/builders/deals/slice/dealTypes';
import CaloriesDisclaimer from '@/common/CaloriesDisclaimer/CaloriesDisclaimer';
import { MenuRecipe } from '../pizza/pizzaMenuTypes';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { useApplicationInitialized } from '@/hooks/useApplicationInitialized';

export interface MenuProps {
  title: string;
  subtitle?: string;
  minimumDisplayedItems?: number | null;
  vegetarianLegend?: boolean;
  seeMoreLess?: boolean;
  caloriesDisclaimer?: boolean;
  itemsTestId: string;
  items?: MenuRecipe[] | DealStep[];
  showMoreInformation?: boolean;
  isNational?: boolean;
  isDeals?: boolean;
  children?: JSX.Element;
}

const Menu = (props: MenuProps): JSX.Element => {
  const {
    title,
    subtitle,
    items = [],
    minimumDisplayedItems,
    vegetarianLegend,
    seeMoreLess,
    caloriesDisclaimer,
    itemsTestId,
    showMoreInformation = true,
    isNational = false,
    isDeals = false,
    children
  } = props;
  const minimumDisplayedItemsQuantity = minimumDisplayedItems || items.length;
  const classes = useStyle();
  const dispatch = useDispatch();
  const [expandedList, setExpandedList] = useState(false);
  const itemTileElList = useRef<HTMLElement[]>([]);
  const toggleSeeAll = () => setExpandedList(!expandedList);

  const analytics = useAnalytics();
  const appInitialized = useApplicationInitialized();
  const [pageLoadAnalyticsPushed, setPageLoadAnalyticsPushed] = useState(false);
  const analyticsItems = getMenuItems([...(items as MenuRecipe[])], title);

  useEffect(() => {
    if (isNational) {
      telemetry.addCustomEvent(
        `national-${title.toLowerCase()}-menu-view-page`
      );
    }
  }, [title, isNational]);

  useEffect(() => {
    if (expandedList) {
      const firstHiddenItem = itemTileElList.current[minimumDisplayedItemsQuantity];
      firstHiddenItem?.querySelector('a')?.focus();
    }
  }, [expandedList, minimumDisplayedItemsQuantity]);

  useEffect(() => {
    if (appInitialized && !pageLoadAnalyticsPushed && title === 'Pizza') {
      analytics.push(() => onMenuInitialLoadAnalytics(title, analyticsItems));
    }
    setPageLoadAnalyticsPushed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appInitialized]);

  function handleLocalizationClick() {
    dispatch(openLocalizationRail());
    analytics.push(() => onNationalClickGetStarted(title));
    telemetry.addCustomEvent(
      `national-${title.toLowerCase()}-menu-get-started-button-click`
    );
  }

  function onTileClick(item: MenuRecipe) {
    const itemName = formatTestId(item.name);
    telemetry.addCustomEvent(
      `national-${title.toLowerCase()}-menu-${itemName}-tile-click`
    );
    dispatch(openLocalizationRail());
  }

  return (
    <CenteredContainer>
      <Grid data-testid="menu">
        <CategoryTitle title={title} />
        {subtitle && (
          <Typography variant="h2" className={classes.subheading}>
            {subtitle}
          </Typography>
        )}
        {showMoreInformation && (
          <>
            <Typography variant="h2" className={classes.subheading}>
              { isDeals
                ? 'Find a restaurant near you for local deals and pricing'
                : 'Find a restaurant to see our full menu and prices'}
            </Typography>
            <Box className={classes.ctaButton}>
              <CtaButton
                color="primary"
                data-testid="get-started-button"
                ariaLabel="Get started by finding your restaurant"
                labelText="Get Started"
                size="large"
                clickHandler={handleLocalizationClick}
              />
            </Box>
          </>
        )}
        {children ?? (
          <MenuTileList
            tileList={items as MenuRecipe[]}
            testId={itemsTestId}
            handleClick={onTileClick}
            tileRef={itemTileElList}
            hasLinkEnabled={!isDeals}
            expandedList={expandedList}
            minimumDisplayedItemsQuantity={minimumDisplayedItemsQuantity}
          />
        )}
        {showMoreInformation && vegetarianLegend && (
          <div
            className={classes.veggieIconContainer}
            data-testid="vegetarian-legend"
            aria-hidden
          >
            <Eco className={classes.vegetarianIcon} />
            <span>Vegetarian</span>
          </div>
        )}
        {seeMoreLess && (
          <Typography align="center">
            <LinkCaret
              text={expandedList ? 'See less' : 'See more'}
              ariaLabel={
                expandedList ? `See less ${title}` : `See more ${title}`
              }
              caretDirection={
                expandedList ? CaretDirection.UP : CaretDirection.DOWN
              }
              onClick={toggleSeeAll}
              dataTestId={`national-see-${expandedList ? 'less' : 'more'}`}
            />
          </Typography>
        )}
        {!!caloriesDisclaimer && <CaloriesDisclaimer />}
      </Grid>
    </CenteredContainer>
  );
};

export default Menu;
